import { useEffect, useState } from 'react';
import { usePage } from '@inertiajs/react';

const showBadge = () => {
    if (!window.grecaptcha) return;
    window.grecaptcha.ready(() => {
        const badge = document.getElementsByClassName('grecaptcha-badge')[0];
        if (!badge) return;
        badge.style.display = 'block';
        badge.style.zIndex = '1'; // Ensure badge is visible
    });
};

const hideBadge = () => {
    if (!window.grecaptcha) return;
    window.grecaptcha.ready(() => {
        const badge = document.getElementsByClassName('grecaptcha-badge')[0];
        if (!badge) return;
        badge.style.display = 'none';
    });
};

const useReCaptcha = () => {
    const { recaptcha_storage_name, recaptcha_site_key } = usePage().props;
    const [reCaptchaLoaded, setReCaptchaLoaded] = useState(false);
    
    // Load reCAPTCHA script
    useEffect(() => {
        if (typeof window === 'undefined' || reCaptchaLoaded) return;
        
        // Check if reCAPTCHA is already loaded
        if (window.grecaptcha) {
            showBadge();
            setReCaptchaLoaded(true);
            return;
        }
        
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.google.com/recaptcha/api.js?render=${recaptcha_site_key}`;
        
        script.addEventListener('load', () => {
            setReCaptchaLoaded(true);
            showBadge();
        });
        
        document.body.appendChild(script);
        
        // Cleanup script on component unmount
        return () => {
            document.body.removeChild(script);
            hideBadge();
        };
    }, [reCaptchaLoaded]);
    
    // Function to generate token
    const generateReCaptchaToken = (action) => {
        return new Promise((resolve, reject) => {
            if (!reCaptchaLoaded) {
                return reject(new Error('ReCaptcha non chargé.'));
            }
            
            if (typeof window === 'undefined' || !window.grecaptcha) {
                setReCaptchaLoaded(false);
                return reject(new Error('ReCaptcha non chargé.'));
            }
            
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(recaptcha_site_key, { action }).then((token) => {
                    window.localStorage.setItem(recaptcha_storage_name, token);
                    resolve(token);
                }).catch((error) => {
                    reject(new Error(`Échec de l'exécution du script reCAPTCHA: ${error}`));
                });
            });
        });
    };
    
    return { reCaptchaLoaded, generateReCaptchaToken };
};

export default useReCaptcha;
