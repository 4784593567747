import ConfirmModal from '@/Components/Common/ConfirmModal.jsx';
import Lightbox from '@/Components/Common/Lightbox.jsx';
import Modal from '@/Components/Common/Modal.jsx';
import NumberTextField from '@/Components/Fields/NumberTextField.jsx';
import PostCodeField from '@/Components/Fields/PostCodeField.jsx';
import RadioGroupField from '@/Components/Fields/RadioGroupField.jsx';
import Section from '@/Components/Layouts/Section.jsx';
import {useNotifications} from '@/Components/Providers/NotificationsProvider.jsx';
import {getUserErrorMessage, handleFieldsErrors} from '@/Scripts/Common/errors.js';
import useReCaptcha from '@/Scripts/Common/useReCaptcha.js';
import ImagesStyle from '@/Styles/images.js';
import {Head, useForm, usePage} from '@inertiajs/react';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
    Alert,
    Box,
    Button,
    FormControlLabel, IconButton,
    InputAdornment,
    Link as MuiLink,
    MenuItem,
    Radio,
    Stack,
    styled,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, {memo, useMemo, useRef, useState} from 'react';

const Fieldset = styled('div')(({theme}) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    gap: '1rem',
    width: '100%',

    [theme.breakpoints.up('md')]: {
        display: 'grid',
        gap: '2rem',
        gridTemplateAreas: `
            "b a"
            "b c"`,
        gridTemplateColumns: '1.5fr 1fr',
        gridTemplateRows: 'auto 1fr',
        alignItems: 'start',

        '& > div:first-of-type': {
            gridArea: 'a',
        },
        '& > *:nth-of-type(2)': {
            gridArea: 'b',
        },
        '& div:last-of-type': {
            gridArea: 'c',
        },
    },
}));

const FieldsetImage = styled('img')(({theme}) => ({
    ...ImagesStyle.cover_center,
    [theme.breakpoints.down('md')]: {
        position: 'relative',
        left: '-24px',
        width: 'calc(100% + 48px)',
    },
}));

const radio_option_style = (theme, checked) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    gap: 1.5,
    width: '100%',
    padding: '12px 16px',
    borderWidth: checked ? 2 : 1,
    borderStyle: 'solid',
    borderColor: checked ? theme.palette.primary.main : theme.palette.border.main,
    borderRadius: 4,
});

const RadioOption = styled('div')(({theme, checked}) => ({
    ...radio_option_style(theme, checked),
    minHeight: 104,
}));

const DarkBox = styled('div')(({theme, sx}) => ({
    padding: '48px 64px',
    backgroundColor: theme.palette.text.dark,
    [theme.breakpoints.down('md')]: {
        padding: '24px',
    },
    ...sx,
}));

const decors = [
    {image: '/images/pages/configurator/decors/decor01.jpg', width: 1920, height: 872},
    {image: '/images/pages/configurator/decors/decor02.jfif', width: 1920, height: 1080},
    {image: '/images/pages/configurator/decors/bedroom01.png', width: 700, height: 700},
    {image: '/images/pages/configurator/decors/bedroom02.jfif', width: 1000, height: 1000},
    {image: '/images/pages/configurator/decors/bedroom03.jfif', width: 1000, height: 429},
    {image: '/images/pages/configurator/decors/decor03.jfif', width: 1920, height: 1080},
    {image: '/images/pages/configurator/decors/decor04.jfif', width: 1920, height: 1080},
    {image: '/images/pages/configurator/decors/decor05.jfif', width: 1920, height: 1080},
    {image: '/images/pages/configurator/decors/bathroom01.png', width: 1000, height: 391},
    {image: '/images/pages/configurator/decors/bathroom02.png', width: 1000, height: 560},
    {image: '/images/pages/configurator/decors/bathroom03.png', width: 1000, height: 706},
];

const land_section_type_options = [
    {label: 'Choisir un terrain parmi nos terrains disponibles', value: 'choice'},
    {label: 'Saisir les informations de mon terrain', value: 'fields'},
];

const repayment_periods_options = [
    {label: '10 ans', value: 120},
    {label: '15 ans', value: 180},
    {label: '20 ans', value: 240},
    {label: '25 ans', value: 300},
];

const profiles_options = [
    {label: 'Premier achat', value: 0},
    {label: 'Investissement à but locatif', value: 2},
    {label: 'Autre', value: 1},
];

const civilities_options = [
    {label: 'Madame', value: 'Madame'},
    {label: 'Monsieur', value: 'Monsieur'},
    {label: 'Laisser vide', value: ''},
];

const RadioChoices = ({items, current_value}) => {
    return useMemo(() => {
        return items.map(({label, value, description, addon}) => (
            <FormControlLabel
                key={label}
                value={value}
                required
                control={<Radio className="usable-hidden" />}
                label={
                    <RadioOption checked={current_value+"" === value}>
                        <Typography variant="body2" component="div">
                            {label}
                        </Typography>
                        {description && (
                            <Typography
                                variant="caption"
                                component="div"
                                sx={(theme) => ({color: theme.palette.text.light})}
                            >
                                {description}
                            </Typography>
                        )}
                        <Typography
                            variant="body2"
                            component="div"
                            sx={{
                                fontWeight: 600,
                                fontStyle: 'italic',
                                textAlign: 'right',
                            }}
                        >
                            {addon}
                        </Typography>
                    </RadioOption>
                }
                sx={{
                    flexFlow: 'column nowrap',
                    alignItems: 'stretch',
                    margin: 0,
                }}
            />
        ));
    }, [items, current_value]);
};

const findOptionOrDefault = (options, type, value) => {
    return value !== ''
        ? options.find((o) => o.type.name === type.name && parseInt(o.value) === value)
        : options.find((o) => o.type.name === type.name && o.default);
};


const Index = ({origin, construction_postcode, price, pricing}) => {
    console.log({pricing})
    const pricing_group = pricing.groups[0];
    const pricing_prices = pricing.prices.map((p) => {
        let options = [];
        try {
            options = JSON.parse(p.config);
            
        } catch(error) {
            console.warn(error)
        }
        
        return {
            ...p,
            options
        }
    })
    const pricing_group_price = !isNaN(pricing_group.amount) ? parseFloat(pricing_group.amount) : undefined
    
    const land_is_preconfigured = construction_postcode && price;

    const current_theme = useTheme();
    const {props: page_props} = usePage();
    const {showNotifications} = useNotifications();
    const {reCaptchaLoaded, generateReCaptchaToken} = useReCaptcha();
    const form = useForm({
        origin_ref: origin ?? '',
        price: price ?? pricing_group_price ?? '',
        construction_location_id: construction_postcode ?? '',

        garage: '',
        bedrooms_qty: '',
        kitchen: '',

        down_payment: 10000,
        interest_rate: 3,
        insurance_rate: 0.3,
        repayment_period: 300,

        civility: '',
        lastname: '',
        firstname: '',
        phone: '',
        email: '',
        postcode: '',
        city: '',
        profil: '',
    });
    const {data, setData, errors, setError, clearErrors, reset} = form;

    const [landSectionType, setLandSectionType] = useState(construction_postcode || price ? 'fields' : '');
    const main_form = useRef(null);
    const budget_details_form = useRef(null);

    const [loading, setLoading] = useState(false);
    const [openingBudgetDetails, setOpeningBudgetDetails] = useState(false);
    const [promptingUserInfo, setPromptingUserInfo] = useState(false);
    
    const garage_type = pricing.options_types.find((t) => t.name.toLowerCase().includes('garage'));
    const bedrooms_qty_type = pricing.options_types.find((t) => t.name.toLowerCase().includes('chambre'));
    const kitchen_type = pricing.options_types.find((t) => t.name.toLowerCase().includes('cuisine'));
    
    const current_option = useMemo(() => ({
        garage: findOptionOrDefault(pricing.options, garage_type, data.garage),
        bedrooms: findOptionOrDefault(pricing.options, bedrooms_qty_type, data.bedrooms_qty),
        kitchen: findOptionOrDefault(pricing.options, kitchen_type, data.kitchen)
    }), [data.garage, data.bedrooms_qty, data.kitchen]);
    
    const current_pricing = pricing_prices.find((pp) => (
        Object.entries(current_option).every(([key, co]) => pp.options.find((ppo) => co.uniq_id === ppo.uniq_id))
    ));
    
    const garage_pricing_prices = pricing_prices.filter((pp) => {
        const ppo_uids = pp.options.map((ppo) => ppo.uniq_id).flat();
        return ppo_uids.includes(current_option.bedrooms.uniq_id) && ppo_uids.includes(current_option.kitchen.uniq_id)
    });
    const kitchen_pricing_prices = pricing_prices.filter((pp) => {
        const ppo_uids = pp.options.map((ppo) => ppo.uniq_id).flat();
        return ppo_uids.includes(current_option.bedrooms.uniq_id) && ppo_uids.includes(current_option.garage.uniq_id)
    });
    const bedrooms_qty_pricing_prices = pricing_prices.filter((pp) => {
        const ppo_uids = pp.options.map((ppo) => ppo.uniq_id).flat();
        return ppo_uids.includes(current_option.garage.uniq_id) && ppo_uids.includes(current_option.kitchen.uniq_id)
    });
    
    const garage_options = pricing.options.filter((o) => o.type.name === garage_type.name)
    const garage_options_uids = garage_options.map((o) => o.uniq_id).flat();
    const kitchen_options = pricing.options.filter((p) => p.type.name === kitchen_type.name)
    const kitchen_options_uids = kitchen_options.map((o) => o.uniq_id).flat();
    const bedrooms_qty_options = pricing.options.filter((p) => p.type.name === bedrooms_qty_type.name)
    const bedrooms_qty_options_uids = bedrooms_qty_options.map((o) => o.uniq_id).flat();
    
    const garage_highest_impact_price = garage_pricing_prices.reduce((highest, current) => {
        return current.amount > highest.amount ? current : highest;
    }, garage_pricing_prices[0]);
    const garage_highest_impact = garage_highest_impact_price?.options.find((hpo) => {
        return garage_options_uids.includes(hpo.uniq_id)
    })?.amount ?? 0;
    
    const kitchen_highest_impact_price = kitchen_pricing_prices.reduce((highest, current) => {
        return current.amount > highest.amount ? current : highest;
    }, kitchen_pricing_prices[0]);
    const kitchen_highest_impact = kitchen_highest_impact_price?.options.find((hpo) => {
        return kitchen_options_uids.includes(hpo.uniq_id)
    })?.amount ?? 0;
    
    const bedrooms_qty_highest_impact_price = bedrooms_qty_pricing_prices.reduce((highest, current) => {
        return current.amount > highest.amount ? current : highest;
    }, bedrooms_qty_pricing_prices[0]);
    const bedrooms_qty_highest_impact = bedrooms_qty_highest_impact_price?.options.find((hpo) => {
        return bedrooms_qty_options_uids.includes(hpo.uniq_id)
    })?.amount ?? 0;
    
    const bedrooms_qty_lowest_impact_price = bedrooms_qty_pricing_prices.reduce((lowest, current) => {
        return current.amount < lowest.amount ? current : lowest;
    }, bedrooms_qty_pricing_prices[0]);
    
    const garage_select_options = garage_options
        .map((o) => {
            const current_price = garage_pricing_prices.find((pp) => {
                const ppo_uids = pp.options.map((ppo) => ppo.uniq_id).flat();
                return ppo_uids.includes(o.uniq_id)
            })
            const current_price_impact = current_price?.options.find((cpo) => cpo.uniq_id === o.uniq_id)?.amount ?? 0;
            const is_highest = current_price.uniq_id === garage_highest_impact_price.uniq_id;
            
            return {
                label: o.name,
                value: o.value,
                addon: is_highest ? "inclus" : "-"+garage_highest_impact+" €",
                impact: current_price_impact,
            }
        });
    
    const kitchen_select_options = kitchen_options
        .map((o) => {
            const current_price = kitchen_pricing_prices.find((pp) => {
                const ppo_uids = pp.options.map((ppo) => ppo.uniq_id).flat();
                return ppo_uids.includes(o.uniq_id)
            })
            const current_price_impact = current_price?.options.find((cpo) => cpo.uniq_id === o.uniq_id)?.amount ?? 0;
            const is_highest = current_price.uniq_id === kitchen_highest_impact_price.uniq_id;
            
            return {
                label: o.name,
                value: o.value,
                addon: !is_highest ? "inclus" : "+"+kitchen_highest_impact+" €",
                impact: current_price_impact,
            }
        });
    
    const bedrooms_qty_select_options = bedrooms_qty_options
        .map((o) => {
            const current_price = bedrooms_qty_pricing_prices.find((pp) => {
                const ppo_uids = pp.options.map((ppo) => ppo.uniq_id).flat();
                return ppo_uids.includes(o.uniq_id)
            })
            const current_price_impact = current_price?.options.find((cpo) => cpo.uniq_id === o.uniq_id)?.amount ?? 0;
            const is_highest = current_price.uniq_id === bedrooms_qty_highest_impact_price.uniq_id;
            const is_lowest = current_price.uniq_id === bedrooms_qty_lowest_impact_price.uniq_id;
            
            return {
                label: o.name,
                value: o.value,
                addon: is_highest ? "+"+bedrooms_qty_highest_impact+" €" : !is_lowest ? "+"+current_price_impact+" €" : "inclus",
                impact: current_price_impact,
            }
        });
    
    const garage_selected = garage_select_options.find((so) => so.value === data.garage+'') ?? ''
    const kitchen_selected = kitchen_select_options.find((so) => so.value === data.kitchen+'') ?? ''
    const bedrooms_qty_selected = bedrooms_qty_select_options.find((so) => so.value === data.bedrooms_qty+'') ?? ''
    
    const pricing_dep_numeros = pricing.departments.map((prd) => prd.numero).flat();
    const pricing_postcodes = pricing.postcodes.map((prd) => prd.code).flat();
    
    const location_increase = useMemo(() => {
        const curr_postcode = data.construction_location_id;
        const dep_num = curr_postcode.slice(0, 2);
        let pricing_dep;
        let pricing_postcode;
        let res = 0
        if(pricing_dep_numeros.includes(dep_num)) {
            pricing_dep = pricing.departments.find((prd) => prd.numero === dep_num)
            res = pricing_dep?.increase ?? 0;
        }
        if(pricing_postcodes.includes(curr_postcode)) {
            pricing_postcode = pricing.postcodes.find((ppc) => ppc.code === curr_postcode);
            res = pricing_postcode?.increase ?? 0;
        }
        if(!isNaN(res)){
           res = parseFloat(res)
        }
        console.log({pricing_dep, pricing_postcode})
        return res;
    }, [data.construction_location_id])

    const budget_allowance = useMemo(() => {
        if (!data.price || isNaN(data.price)) {
            return '';
        }
        let res = parseFloat(data.price);
        if (garage_selected) {
            res = parseFloat(parseFloat(res + garage_selected.impact).toFixed(0));
        }
        if (kitchen_selected) {
            res = parseFloat(parseFloat(res + kitchen_selected.impact).toFixed(0));
        }
        if (bedrooms_qty_selected) {
            res = parseFloat(parseFloat(res + bedrooms_qty_selected.impact).toFixed(0));
        }
        if(location_increase) {
            res = parseFloat(parseFloat(res * location_increase).toFixed(0));
        }
        return res;
    }, [data.price, data.garage, data.bedrooms_qty, data.kitchen, location_increase]);

    const budget_allowance_width = useMemo(
        () =>
            budget_allowance === ''
                ? '100%'
                : ((budget_allowance + '').length + 1) * current_theme.typography.htmlFontSize,
        [budget_allowance],
    );
    const amount_max_monthly = useMemo(() => {
        if (
            !budget_allowance ||
            isNaN(budget_allowance) ||
            !data.interest_rate ||
            isNaN(data.interest_rate) ||
            !data.repayment_period ||
            isNaN(data.repayment_period) ||
            !data.insurance_rate ||
            isNaN(data.insurance_rate)
        ) {
            return '';
        }

        const real_budget = budget_allowance - data.down_payment;
        const monthly_rate = data.interest_rate / 100 / 12;
        const total_payments = data.repayment_period;

        const monthly_payment =
            (real_budget * monthly_rate * Math.pow(1 + monthly_rate, total_payments)) /
            (Math.pow(1 + monthly_rate, total_payments) - 1);
        const insurance_payment = (real_budget * data.insurance_rate) / 100 / 12;
        const total_monthly_payment = monthly_payment + insurance_payment;

        return parseFloat(total_monthly_payment.toFixed(0));
    }, [budget_allowance, data.down_payment, data.interest_rate, data.repayment_period, data.insurance_rate]);

    const isValidForm = (form) => {
        return !form || form.checkValidity();
    };

    const openBudgetDetails = () => {
        setOpeningBudgetDetails(true);
    };

    const closeBudgetDetails = () => {
        setOpeningBudgetDetails(false);
    };

    const openPromptUserInfo = (e) => {
        e.preventDefault();

        if (!isValidForm(main_form?.current)) {
            return;
        }
        if (!isValidForm(budget_details_form?.current)) {
            openBudgetDetails();
            setTimeout(() => {
                budget_details_form.current.reportValidity();
            }, 0);
            return;
        }
        setPromptingUserInfo(true);
    };

    const closePromptUserInfo = () => {
        setPromptingUserInfo(false);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        clearErrors(name);
        setData(name, value);
    };

    const handleControlledChange = ({name, value}) => {
        clearErrors(name);
        setData(name, value);
    };

    const handleNumericOptionChange = (e) => {
        const {name, value} = e.target;
        console.log({name, value})
        clearErrors(name);
        setData(name, parseInt(value));
    };

    const handleLandSectionTypeChange = (e) => {
        const {value} = e.target;
        setLandSectionType(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({data});

        if (errors?.construction_location_id) {
            showNotifications("Le champ automatique d'adresse de construction n'est pas renseigné.", {
                severity: 'error',
                autoHideDuration: 3000,
            });
            return;
        }
        data.url_brand = page_props?.url_brand;
        data.budget_allowance = budget_allowance;
        data.amount_max_monthly = amount_max_monthly;

        if (!reCaptchaLoaded) {
            console.error('ReCAPTCHA pas encore chargé.');
            return;
        }

        (async () => {
            try {
                const token = await generateReCaptchaToken('submit_form');
                const response = await axios({
                    url: route('configurator.store'),
                    method: 'POST',
                    data: {
                        'g-recaptcha-response': token,
                        ...data,
                    },
                });
                const resp_data = response?.data ?? {};

                if (resp_data?.message) {
                    showNotifications(resp_data.message, {
                        severity: 'success',
                        autoHideDuration: 3000,
                    });
                }
                closePromptUserInfo();
                reset();
            } catch (error) {
                const error_message = getUserErrorMessage(error);
                showNotifications(error_message, {
                    severity: 'error',
                    autoHideDuration: 3000,
                });
                handleFieldsErrors(error, setError);
            } finally {
                setLoading(false);
            }
        })();
    };

    return (
        <>
            <Head title="Configurateur" />

            {page_props?.flash?.message && (
                <Alert severity="success" sx={{justifyContent: 'center'}}>
                    {page_props.flash.message}
                </Alert>
            )}
            <Box component="form" ref={main_form} onSubmit={openPromptUserInfo}>
                <Section>
                    <Box sx={{mb: {xs: 4, md: 6}}}>
                        <Typography variant="fancy_h3" component="h1" sx={{mb: 1.5}}>
                            Configuration de votre projet
                        </Typography>
                        <Typography variant="subtitle2" component="p">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua.
                        </Typography>
                    </Box>

                    <Fieldset>
                        <Box>
                            <Typography variant="fancy_h5" component="h2" sx={{textAlign: {xs: 'center', md: 'start'}}}>
                                Mon terrain {land_is_preconfigured ? ' configuré' : ''}
                            </Typography>
                        </Box>

                        <Lightbox id={'gallery-land'}>
                            <MuiLink
                                href={decors[0].image}
                                data-pswp-width={decors[0].width}
                                data-pswp-height={decors[0].height}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FieldsetImage src={decors[0].image} alt="" />
                            </MuiLink>
                        </Lightbox>

                        <Box>
                            {!land_is_preconfigured && (
                                <RadioGroupField
                                    fullWidth
                                    id="land-section-type"
                                    name="land_section_type"
                                    sx={{
                                        mb: 2,
                                        '& .MuiFormControlLabel-asterisk': {
                                            display: 'none',
                                        },
                                    }}
                                    RadioGroupProps={{
                                        value: landSectionType,
                                        sx: {gap: 2},
                                        onChange: handleLandSectionTypeChange,
                                    }}
                                >
                                    {land_section_type_options.map(({label, value}) => (
                                        <FormControlLabel
                                            key={label}
                                            value={value}
                                            required
                                            control={<Radio />}
                                            label={label}
                                        />
                                    ))}
                                </RadioGroupField>
                            )}
                            {landSectionType === 'fields' && (
                                <Box>
                                    <NumberTextField
                                        id="price"
                                        name="price"
                                        value={data.price}
                                        fullWidth
                                        required
                                        label="Prix"
                                        error={!!errors?.price}
                                        helperText={errors.price}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                            },
                                        }}
                                        sx={{mb: 2}}
                                        onChange={handleControlledChange}
                                    />

                                    <PostCodeField
                                        name={'construction_location_id'}
                                        value={data.construction_location_id}
                                        fullWidth
                                        required
                                        label="Code postal de construction"
                                        error={!!errors?.construction_location_id}
                                        helperText={errors.construction_location_id}
                                        sx={{mb: 2}}
                                        onChange={handleControlledChange}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Fieldset>
                </Section>
                <Section>
                    <Fieldset>
                        <Box>
                            <Typography variant="fancy_h5" component="h2" sx={{textAlign: {xs: 'center', md: 'start'}}}>
                                Extérieur
                            </Typography>
                        </Box>

                        <Lightbox id={'gallery-exterior'}>
                            <MuiLink
                                href={decors[1].image}
                                data-pswp-width={decors[1].width}
                                data-pswp-height={decors[1].height}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FieldsetImage src={decors[1].image} alt="" />
                            </MuiLink>
                        </Lightbox>

                        <Box>
                            <Typography variant="body1" component="p" sx={{mb: {xs: 2, md: 4}}}>
                                Présence garage
                            </Typography>
                            <RadioGroupField
                                fullWidth
                                error={!!errors.garage}
                                helperText={errors.garage}
                                id="garage"
                                name="garage"
                                sx={{
                                    '& .MuiFormControlLabel-asterisk': {
                                        display: 'none',
                                    },
                                }}
                                RadioGroupProps={{
                                    value: data.garage,
                                    sx: {gap: 2},
                                    onChange: handleNumericOptionChange,
                                }}
                            >
                                <RadioChoices items={garage_select_options} current_value={data.garage} />
                            </RadioGroupField>
                        </Box>
                    </Fieldset>
                </Section>
                <Box
                    sx={(theme) => ({
                        backgroundColor: theme.palette.background.light,
                    })}
                >
                    <Section
                        sx={{
                            overflowX: 'auto',
                            overflowY: 'hidden',
                        }}
                    >
                        <Box sx={{mb: {xs: 4, md: 6}}}>
                            <Typography variant="fancy_h5" component="h2" sx={{textWrap: 'balance'}}>
                                Comment organiser la chambre parentale ?
                            </Typography>
                        </Box>

                        <Lightbox
                            id={'gallery-bedroom-organisation'}
                            sx={(theme) => ({
                                display: 'grid',
                                gridTemplateColumns: 'auto',
                                gridTemplateAreas: `
                                    "a a"
                                    "b c"
                                    "d d"
                                    "e e"`,
                                gap: 2,
                                height: '100%',
                                width: '100%',
                                [theme.breakpoints.up('md')]: {
                                    gridTemplateColumns: '1fr 1fr',
                                    gridTemplateAreas: `
                                    "a c"
                                    "b c"
                                    "d e"`,
                                    gap: 4,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    gridTemplateColumns: '1fr 1fr 1fr',
                                    gridTemplateAreas: `
                                    "a c d"
                                    "b c d"
                                    "b c e"`,
                                },
                            })}
                        >
                            <DarkBox
                                sx={{gridArea: 'a', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            >
                                <Typography variant="subtitle1" color="white" component="p">
                                    Des rangements et un valet de chambre pour y déposer vos vêtements à reprendre
                                    demain.
                                </Typography>
                            </DarkBox>

                            <MuiLink
                                href={decors[2].image}
                                data-pswp-width={decors[2].width}
                                data-pswp-height={decors[2].height}
                                target="_blank"
                                rel="noreferrer"
                                sx={{
                                    display: 'inline-block',
                                    gridArea: 'b',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={decors[2].image}
                                    style={{...ImagesStyle.cover_center}}
                                    alt=""
                                />
                            </MuiLink>
                            <MuiLink
                                href={decors[3].image}
                                data-pswp-width={decors[3].width}
                                data-pswp-height={decors[3].height}
                                target="_blank"
                                rel="noreferrer"
                                sx={{
                                    display: 'inline-block',
                                    gridArea: 'c',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={decors[3].image}
                                    style={{...ImagesStyle.cover_center}}
                                    alt=""
                                />
                            </MuiLink>
                            <MuiLink
                                href={decors[4].image}
                                data-pswp-width={decors[4].width}
                                data-pswp-height={decors[4].height}
                                target="_blank"
                                rel="noreferrer"
                                sx={{
                                    display: 'inline-block',
                                    gridArea: 'd',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={decors[4].image}
                                    style={{...ImagesStyle.cover_center}}
                                    alt=""
                                />
                            </MuiLink>
                            <DarkBox
                                sx={{gridArea: 'e', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            >
                                <Typography variant="subtitle1" color="white" component="p">
                                    Choisissez des couleurs claires et chaudes pour favoriser la relaxation et votre
                                    sommeil.
                                </Typography>
                            </DarkBox>
                        </Lightbox>
                    </Section>
                </Box>
                <Section>
                    <Fieldset>
                        <Box>
                            <Typography variant="fancy_h5" component="h2" sx={{textAlign: {xs: 'center', md: 'start'}}}>
                                Chambres
                            </Typography>
                        </Box>

                        <Lightbox id={'gallery-bedrooms-qty'}>
                            <MuiLink
                                href={decors[5].image}
                                data-pswp-width={decors[5].width}
                                data-pswp-height={decors[5].height}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FieldsetImage src={decors[5].image} alt="" />
                            </MuiLink>
                        </Lightbox>

                        <Box>
                            <Typography variant="body1" component="p" sx={{mb: {xs: 2, md: 4}}}>
                                Nombre de chambres
                            </Typography>
                            <RadioGroupField
                                fullWidth
                                error={!!errors.bedrooms_qty}
                                helperText={errors.bedrooms_qty}
                                id="bedrooms-qty"
                                name="bedrooms_qty"
                                sx={{
                                    '& .MuiFormControlLabel-asterisk': {
                                        display: 'none',
                                    },
                                }}
                                RadioGroupProps={{
                                    value: data.bedrooms_qty,
                                    sx: {gap: 2},
                                    onChange: handleNumericOptionChange,
                                }}
                            >
                                <RadioChoices items={bedrooms_qty_select_options} current_value={data.bedrooms_qty} />
                            </RadioGroupField>
                        </Box>
                    </Fieldset>
                </Section>
                <Section>
                    <Fieldset>
                        <Box>
                            <Typography variant="fancy_h5" component="h2" sx={{textAlign: {xs: 'center', md: 'start'}}}>
                                Cuisine
                            </Typography>
                        </Box>

                        <Lightbox id={'gallery-open-kitchen'}>
                            <MuiLink
                                href={decors[6].image}
                                data-pswp-width={decors[6].width}
                                data-pswp-height={decors[6].height}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FieldsetImage src={decors[6].image} alt="" />
                            </MuiLink>
                        </Lightbox>

                        <Box>
                            <Typography variant="body1" component="p" sx={{mb: {xs: 2, md: 4}}}>
                                Ouverture de la cuisine
                            </Typography>
                            <RadioGroupField
                                fullWidth
                                error={!!errors.kitchen}
                                helperText={errors.kitchen}
                                id="kitchen"
                                name="kitchen"
                                sx={{
                                    '& .MuiFormControlLabel-asterisk': {
                                        display: 'none',
                                    },
                                }}
                                RadioGroupProps={{
                                    value: data.kitchen,
                                    sx: {gap: 2},
                                    onChange: handleNumericOptionChange,
                                }}
                            >
                                <RadioChoices items={kitchen_select_options} current_value={data.kitchen} />
                            </RadioGroupField>
                        </Box>
                    </Fieldset>
                </Section>

                <Box
                    sx={(theme) => ({
                        backgroundColor: theme.palette.background.light,
                    })}
                >
                    <Section
                        sx={{
                            overflowX: 'auto',
                            overflowY: 'hidden',
                        }}
                    >
                        <Box sx={{mb: {xs: 4, md: 6}}}>
                            <Typography variant="fancy_h5" component="h2" sx={{textWrap: 'balance'}}>
                                Plutôt douche ou baignoire ?
                            </Typography>
                        </Box>

                        <Lightbox
                            id={'gallery-shower-or-bath'}
                            sx={(theme) => ({
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gridTemplateAreas: `
                                    "a a"
                                    "b d"
                                    "b c"`,
                                gap: 2,
                                height: '100%',
                                width: '100%',
                                [theme.breakpoints.up('md')]: {
                                    gap: 4,
                                },
                                [theme.breakpoints.up('lg')]: {
                                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                    gridTemplateAreas: `
                                        "a a b b"
                                        "c d b b"`,
                                },
                            })}
                        >
                            <MuiLink
                                href={decors[8].image}
                                data-pswp-width={decors[8].width}
                                data-pswp-height={decors[8].height}
                                target="_blank"
                                rel="noreferrer"
                                sx={{
                                    display: 'inline-block',
                                    gridArea: 'a',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={decors[8].image}
                                    style={{...ImagesStyle.cover_center}}
                                    alt=""
                                />
                            </MuiLink>
                            <MuiLink
                                href={decors[10].image}
                                data-pswp-width={decors[10].width}
                                data-pswp-height={decors[10].height}
                                target="_blank"
                                rel="noreferrer"
                                sx={{
                                    display: 'inline-block',
                                    gridArea: 'b',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={decors[10].image}
                                    style={{...ImagesStyle.cover_center}}
                                    alt=""
                                />
                            </MuiLink>
                            <MuiLink
                                href={decors[9].image}
                                data-pswp-width={decors[9].width}
                                data-pswp-height={decors[9].height}
                                target="_blank"
                                rel="noreferrer"
                                sx={{
                                    display: 'inline-block',
                                    gridArea: 'c',
                                }}
                            >
                                <Box
                                    component="img"
                                    src={decors[9].image}
                                    style={{...ImagesStyle.cover_center}}
                                    alt=""
                                />
                            </MuiLink>
                            <DarkBox sx={{gridArea: 'd', p: 4}}>
                                <Typography variant="subtitle1" color="white" component="p">
                                    La baignoire…
                                </Typography>
                            </DarkBox>
                        </Lightbox>
                    </Section>
                </Box>
                <Section>
                    <Fieldset>
                        <Box>
                            <Typography variant="fancy_h5" component="h2" sx={{textAlign: {xs: 'center', md: 'start'}}}>
                                Aperçu du projet final
                            </Typography>
                        </Box>

                        <Lightbox id={'gallery-budget'}>
                            <MuiLink
                                href={decors[7].image}
                                data-pswp-width={decors[7].width}
                                data-pswp-height={decors[7].height}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FieldsetImage src={decors[7].image} alt="" />
                            </MuiLink>
                        </Lightbox>

                        <Box>
                            <Typography variant="fancy_subtitle1" component="p" sx={{mb: {xs: 2, md: 4}}}>
                                Budget estimé du projet
                            </Typography>
                            <Stack
                                direction="row"
                                sx={{flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', mb: 10}}
                            >
                                <Typography variant="h4" component="span" sx={{fontWeight: 600}}>
                                    À partir de <br /> {amount_max_monthly} € / mois *
                                </Typography>
                                <MuiLink
                                    variant="body1"
                                    underline="hover"
                                    onClick={openBudgetDetails}
                                    sx={{fontStyle: 'italic', color: 'inherit'}}
                                >
                                    Voir le détail
                                </MuiLink>
                            </Stack>
                            <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
                                <Button
                                    variant="outlined"
                                    color="text"
                                    href=""
                                    size="large"
                                    fullWidth
                                    startIcon={<SaveAltOutlinedIcon size="small" />}
                                    sx={{mx: 'auto', mb: 1.5, maxWidth: 300}}
                                >
                                    Télécharger le récapitulatif PDF
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    endIcon={<ArrowRightAltOutlinedIcon size="small" />}
                                    sx={{mx: 'auto', maxWidth: 300}}
                                >
                                    Finaliser mon projet
                                </Button>
                            </Box>
                        </Box>
                    </Fieldset>
                </Section>
            </Box>

            <Modal
                keepMounted
                id="update-budget-details-modal-dialog"
                ref={budget_details_form}
                open={openingBudgetDetails}
                isForm={true}
                onClose={closeBudgetDetails}
                title={<>
                    <Box>Détail du budget</Box>
                    <IconButton
                        aria-label="close"
                        onClick={closeBudgetDetails}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                </>}
                DialogTitleProps={{
                    sx: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'between',
                    }
                }}
            >
                
                <Box sx={{mt: 2}}>
                    <TextField
                        id="budget_allowance"
                        type="number"
                        name="budget_allowance"
                        label="Budget estimé"
                        value={budget_allowance}
                        fullWidth
                        readOnly
                        required
                        slotProps={{
                            label: {
                                shrink: false,
                                sx: {
                                    whiteSpace: 'nowrap',
                                    overflow: 'visible',
                                    width: '100%',
                                },
                            },
                            input: {
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                sx: {
                                    width: budget_allowance_width,
                                },
                            },
                        }}
                        sx={{
                            ...(budget_allowance === '' ? {display: 'none'} : {}),
                            mb: 2,
                        }}
                    />
                    <NumberTextField
                        id="down-payment"
                        name="down_payment"
                        label="Apport"
                        value={data.down_payment}
                        fullWidth
                        required
                        error={!!errors?.down_payment}
                        helperText={errors.down_payment}
                        step="1"
                        min="0"
                        slotProps={{
                            input: {
                                step: '1',
                                min: '0',
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            },
                        }}
                        sx={{mb: 2}}
                        onChange={handleControlledChange}
                    />
                    <NumberTextField
                        id="interest-rate"
                        name="interest_rate"
                        label="Taux d'intérêt annuel"
                        value={data.interest_rate}
                        fullWidth
                        required
                        error={!!errors?.interest_rate}
                        helperText={errors.interest_rate}
                        step="0.01"
                        min="0"
                        slotProps={{
                            input: {
                                step: '0.01',
                                min: '0',
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            },
                        }}
                        sx={{mb: 2}}
                        onChange={handleControlledChange}
                    />
                    <NumberTextField
                        id="insurance-rate"
                        name="insurance_rate"
                        label="Taux d'assurance"
                        value={data.insurance_rate}
                        fullWidth
                        required
                        error={!!errors?.insurance_rate}
                        helperText={errors.insurance_rate}
                        step="0.01"
                        min="0"
                        slotProps={{
                            input: {
                                step: '0.01',
                                min: '0',
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            },
                        }}
                        sx={{mb: 2}}
                        onChange={handleControlledChange}
                    />

                    <TextField
                        select
                        id="repayment-period"
                        name="repayment_period"
                        label="Durée de remboursement"
                        value={data.repayment_period}
                        fullWidth
                        required
                        error={!!errors?.repayment_period}
                        helperText={errors.repayment_period}
                        onChange={handleChange}
                    >
                        {repayment_periods_options.map(({label, value}) => (
                            <MenuItem key={value} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
                <Box sx={{p: 2, display: 'flex', justifyContent: 'center'}}>
                    <Typography variant="h4" component="span" sx={{textAlign: 'center', fontWeight: 600}}>
                        À partir de <br /> {amount_max_monthly} € / mois *
                    </Typography>
                </Box>
            </Modal>
            <ConfirmModal
                keepMounted
                id="add-user-info-modal-dialog"
                open={promptingUserInfo}
                onClose={closePromptUserInfo}
                onConfirm={handleSubmit}
                isForm={true}
                title={<>M'enregistrer</>}
                confirmButtonProps={{
                    loading,
                }}
                cancelButtonProps={{
                    loading,
                }}
            >
                <RadioGroupField
                    fullWidth
                    error={!!errors.civility}
                    helperText={errors.civility}
                    id="civility"
                    name="civility"
                    sx={{mb: 2}}
                    RadioGroupProps={{
                        row: true,
                        value: data.civility,
                        onChange: handleChange,
                    }}
                >
                    {civilities_options.map(({label, value}) => (
                        <FormControlLabel key={label} value={value} label={label} control={<Radio />} />
                    ))}
                </RadioGroupField>

                <TextField
                    id="firstname"
                    name="firstname"
                    value={data.firstname}
                    fullWidth
                    required
                    autoComplete="name"
                    label="Prénom"
                    error={!!errors?.firstname}
                    helperText={errors.firstname}
                    sx={{mb: 2}}
                    onChange={handleChange}
                />
                <TextField
                    id="lastname"
                    name="lastname"
                    value={data.lastname}
                    fullWidth
                    required
                    autoComplete="name"
                    label="Nom"
                    error={!!errors?.lastname}
                    helperText={errors.lastname}
                    sx={{mb: 2}}
                    onChange={handleChange}
                />

                <TextField
                    id="phone"
                    type="number"
                    name="phone"
                    value={data.phone}
                    fullWidth
                    required
                    autoComplete="tel"
                    label="Téléphone"
                    error={!!errors?.phone}
                    helperText={errors.phone}
                    sx={{mb: 2}}
                    onChange={handleChange}
                />

                <TextField
                    id="email"
                    type="email"
                    name="email"
                    value={data.email}
                    fullWidth
                    required
                    autoComplete="username"
                    label="Adresse email"
                    error={!!errors?.email}
                    helperText={errors.email}
                    sx={{mb: 2}}
                    onChange={handleChange}
                />

                <PostCodeField
                    value={data.postcode}
                    fullWidth
                    required
                    label="Code postal"
                    error={!!errors?.postcode}
                    helperText={errors.postcode}
                    sx={{mb: 2}}
                    onChange={handleControlledChange}
                />

                <TextField
                    id="city"
                    name="city"
                    value={data.city}
                    fullWidth
                    required
                    label="Ville"
                    error={!!errors?.city}
                    helperText={errors.city}
                    sx={{mb: 2}}
                    onChange={handleChange}
                />

                <TextField
                    select
                    id="profil"
                    name="profil"
                    label="Votre profil"
                    value={data.profil}
                    fullWidth
                    error={!!errors?.profil}
                    helperText={errors.profil}
                    onChange={handleChange}
                >
                    {profiles_options.map(({label, value}) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>
            </ConfirmModal>
        </>
    );
};

export default memo(Index);
