import {formatAsPostalCode} from '@/Scripts/Common/formats.jsx';
import {TextField} from '@mui/material';
import * as React from 'react';
import {forwardRef, memo, useEffect, useState} from 'react';

const PostCodeField = forwardRef(({ id, name, value = '', onChange, ...props }, ref) => {
    const [formattedValue, setFormattedValue] = useState(value);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const new_value = formatAsPostalCode(value);
        setFormattedValue(new_value);
        if (typeof onChange === 'function') {
            onChange({ name, value: new_value });
        }
    };

    useEffect(() => {
        setFormattedValue(formatAsPostalCode(value));
    }, [value]);

    return (
        <TextField
            ref={ref}
            type="text"
            id={id ?? 'postcode'}
            name={name ?? 'postcode'}
            value={formattedValue}
            onChange={handleChange}
            {...props}
        />
    );
});

export default memo(PostCodeField);
