export const formatAsNumber = (text) => {
    // Step 1: Remove all characters except digits, minus signs, dots, and commas
    let sanitizedText = (text + '').replace(/[^0-9\-,.]/g, '');

    // Step 2: Ensure minus sign is only at the beginning
    if (sanitizedText.indexOf('-') > 0) {
        sanitizedText = sanitizedText.replace(/-/g, ''); // Remove all minus signs
    }
    // Keep the minus sign only if it's the first character
    sanitizedText = sanitizedText.startsWith('-')
        ? '-' + sanitizedText.slice(1).replace(/-/g, '')
        : sanitizedText.replace(/-/g, '');

    // Step 3: Replace commas with dots
    sanitizedText = sanitizedText.replace(',', '.');

    // Step 4: If there are multiple dots, keep only the first one
    const parts = sanitizedText.split('.');
    if (parts.length > 2) {
        sanitizedText = parts[0] + '.' + parts.slice(1).join('');
    }

    // Step 5: Remove leading zeros (but keep them if they are before a dot)
    if (sanitizedText.startsWith('-')) {
        const negativePart = sanitizedText.slice(1);
        sanitizedText = '-' + negativePart.replace(/^0+(?=\d)/, '');
    } else {
        sanitizedText = sanitizedText.replace(/^0+(?=\d)/, '');
    }

    return sanitizedText;
};

export const formatAsPostalCode = (text) => {
    // Remove all characters except digits and dashes
    let sanitizedText = text.replace(/[^0-9-]/g, '');

    // Remove dashes if there are any, and then insert a dash if it's a valid postal code format
    sanitizedText = sanitizedText.replace(/-/g, '');

    // Format postal code according to a common format
    // Assuming the postal code format is 5 digits, optionally followed by a dash and 4 digits (e.g., "12345-6789")
    if (sanitizedText.length > 5) {
        sanitizedText = sanitizedText.slice(0, 5)/* + '-' + sanitizedText.slice(5, 9)*/;
    }

    return sanitizedText;
};

export const formatAsPhoneNumber = (text) => {
    // Step 1: Remove all characters except digits, spaces, and the plus sign
    let sanitizedText = text.replace(/[^0-9+ ]/g, '');

    // Step 2: Handle international prefix
    // If there's a plus sign, make sure it's at the beginning of the string
    if (sanitizedText.indexOf('+') > 0) {
        sanitizedText = sanitizedText.replace(/\+/g, ''); // Remove all plus signs
    }
    // Keep the plus sign only if it's the first character
    sanitizedText = sanitizedText.startsWith('+')
        ? '+' + sanitizedText.slice(1).replace(/\+/g, '')
        : sanitizedText.replace(/\+/g, '');

    // Step 3: Remove all spaces for now
    sanitizedText = sanitizedText.replace(/\s+/g, '');

    // Step 4: Apply formatting rules
    if (sanitizedText.startsWith('+')) {
        // Format international numbers
        if (sanitizedText.startsWith('+33')) {
            // French international format (e.g., +33 6 12 34 56 78)
            sanitizedText = sanitizedText.slice(3); // Remove the +33
            sanitizedText = sanitizedText.replace(/(\d{2})(?=\d)/g, '$1 ').trim(); // Add spaces every two digits
            sanitizedText = '+33 ' + sanitizedText; // Add +33 back
        } else {
            // For other EU numbers, group the digits in a readable way, similar to French formatting
            sanitizedText = sanitizedText.replace(/(\d{3})(?=\d)/g, '$1 ').trim(); // Add spaces every three digits
        }
    } else {
        // Format French domestic numbers (e.g., 06 12 34 56 78)
        if (sanitizedText.length === 10) {
            sanitizedText = sanitizedText.replace(/(\d{2})(?=\d)/g, '$1 ').trim(); // Add spaces every two digits
        } else {
            // For shorter or malformed numbers, just return as is without spaces
            return sanitizedText;
        }
    }

    return sanitizedText;
};
