import {Box} from '@mui/material';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import React, {forwardRef, memo, useEffect} from 'react';
import 'photoswipe/style.css';

const Lightbox = forwardRef(({ id, children, ...props }, ref) => {
    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#' + id,
            children: 'a',
            pswpModule: () => import('photoswipe'),
        });
        lightbox.init();

        return () => {
            lightbox.destroy();
            lightbox = null;
        };
    }, []);

    return (
        <Box className="pswp-gallery" id={id} {...props}>
            {children}
        </Box>
    );
});

export default memo(Lightbox);
